import { LogAgentApiInResponse } from "@/services/ClientInterfaces";
import { defineStore } from "pinia";


const LocalStorageKeyName = "ibc:agents";

export const loginStore = defineStore("loginStore", {
    state: () => {
        return {
            authenticated: false,
            initialized: false,
            details: null as (LogAgentApiInResponse | null)
        };
    },
    actions: {
        logIn(details: LogAgentApiInResponse) {
            this.authenticated = true;
            this.details = details;

            localStorage.setItem(LocalStorageKeyName, JSON.stringify(details));
        },

        logOut() {
            this.authenticated = false;
            this.details = null;

            localStorage.removeItem(LocalStorageKeyName);
        },

        initialize() {
            const storedDetails = localStorage.getItem(LocalStorageKeyName)
                ? JSON.parse(localStorage.getItem(LocalStorageKeyName) as string)
                : null;

            if (storedDetails) {
                this.logIn(storedDetails);
            }

            this.initialized = true;
        }
    }
});
