/* eslint-disable @typescript-eslint/no-var-requires */
import Vue from "vue";
import { pinia } from "@/stores";
import { createPinia, PiniaVuePlugin } from "pinia";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import VuePhoneNumberInput from "vue-phone-number-input";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);

import Component from 'vue-class-component'
import { DataClient } from "@/services/DataClient";
import { loginStore } from "@/stores/LoginStore";

const GmapVue = require("gmap-vue");


Vue.config.productionTip = false;

// Vue.use(PiniaVuePlugin);
// const pinia = createPinia();

// eslint-disable-next-line @typescript-eslint/no-var-requires
const mask = require("v-mask");

Vue.use(GmapVue, {
    load: {
        key: "AIzaSyAP_k3Z7wZuKRObqWdbZ7ultMHfyc-A-Hg"
    },
    installComponents: true
});

Vue.use(PerfectScrollbar)
Vue.directive("mask", mask.VueMaskDirective);

Vue.component("LanguageIcon", () => import("./components/LanguageIcon.vue"));


// Register the router hooks with their names
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);

async function start() {
    // refresh credentials if needed
    const LocalStorageKeyName = "ibc:agents";
    let storedDetails = localStorage.getItem(LocalStorageKeyName)
        ? JSON.parse(localStorage.getItem(LocalStorageKeyName) as string)
        : null;

    // if there's a parameter in the url "rt" use it as token
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('rt');
    if (token) {
        storedDetails = { token };

        // remove the token from the url
        urlParams.delete('rt');
        window.history.replaceState({}, document.title, `${window.location.pathname}?${urlParams}`);
    }

    if (storedDetails) {
        localStorage.removeItem(LocalStorageKeyName);

        try {
            const r = await DataClient.Agencies.logIn({
                organization: storedDetails.organization,
                token: storedDetails.token,
            });
            localStorage.setItem(LocalStorageKeyName, JSON.stringify(r));
        } catch (e) {
            localStorage.removeItem(LocalStorageKeyName);
            console.error(e);
        }
    }

    new Vue({
        router,
        vuetify,
        pinia,
        render: (h) => h(App),
    }).$mount("#app");
}

start();
